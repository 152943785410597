export default {
    anchors: {
        business: 'enseignes',
        contact: 'contact',
        experiences: 'essence',
        jobs: 'postes-disponibles',
        profiles: 'profils'
    },
    beet: 'Site conçu et developpé par',
    cvLimit: "Le fichier ne doit pas dépasser 10 Mo.",
    hours: "{hours} heures par semaine",
    salary: "{salary}$ de l'heure",
    errors: {
        file: 'Le fichier ne doit pas dépasser 10 Mo.',
        email: "L'adresse email est invalide.",
        emailSize: "Le champ adresse email ne doit pas dépasser 255 caractères.",
        title: "Le champ Titre du poste ne doit pas dépasser 255 caractères.",
        motivation: "Le champ Pourquoi désirez-vous travailler pour le groupe Maestro ne doit pas dépasser 500 caractères.",
        name: "Le champ Nom ne doit pas dépasser 255 caractères."
    }
}
