import DropDown from '@/components/global/DropDown.vue'
import Icon from '@/components/global/Icon.vue'
import Image from '@/components/global/Image.vue'
import LangRouterLink from '@/components/global/LangRouterLink.vue'
import RawHtml from '@/components/global/RawHtml.vue'

const globals = [DropDown, Icon, Image, LangRouterLink, RawHtml]

export { DropDown, Icon, Image, LangRouterLink, RawHtml }

export default Vue => globals.forEach(component => Vue.component(component.name, component))
