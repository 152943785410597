<template>
    <section class="business__mobile -testimonies">
        <swiper :modules="modules" :pagination="true" slides-per-view="auto" :space-between="10" :loop="true">
          <swiper-slide
            v-for="(profile, indexProfiles) in profiles"
            :key="indexProfiles"
            class="business__element-mobile -history"
            target="_blank"
          >
          <img
              class="image__profile"
              :src="profile.image.original"
              :alt="profile.name"
            />
            <div class="profiles__description">
              <p class="profiles__name">{{ profile.name }}</p>
              <p class="profiles__experience">{{ profile.experience }}</p>
              <p class="profiles__text">{{ profile.description }}</p>
              <raw-html class="profiles__list list" :html="profile.history" />
              
            </div>
          </swiper-slide>
        </swiper>
    </section>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Pagination } from 'swiper';
  
  export default {
    name: "MobileSwiperProfiles",
    components: { Swiper, SwiperSlide },
    data() {
      return {
          modules: [Pagination]
      }
    },
    props: {
      profiles: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  