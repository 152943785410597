<template>
    <section class="spontaneous__background section" :id="$t('anchors.contact')" data-light-menu="true" :data-section="$t('anchors.contact')">
        <div class="spontaneous">
            <h2 class="spontaneous__title" v-reveal>{{ content.title }}</h2>
            <p class="description -subtitle -closer" v-reveal="{delay: 200}">{{ content.subtitle }}</p>
            <RawHtml class="spontaneous__description markdown" :html="content.description ?? content.content"
                     v-reveal="{delay: 250}"/>
            <ApplyForm :job-id="jobId"/>
        </div>
    </section>
</template>

<script>
import {ApplyForm} from '@/components/blocks'
import {RawHtml} from '@/components/global'

export default {
    name: 'FormSection',
    components: {ApplyForm, RawHtml},
    props: {
        content: {
            type: Object,
            required: true
        },
        jobId: {
            type: Number,
            default: null
        }
    }
}
</script>