<template>
    <h2 class="advantages__title" v-reveal>{{ content.title }}</h2>
    <p class="description -subtitle advantages__subtitle" v-reveal="{delay: 200}">
        {{ content.subtitle }}
    </p>
    <SwiperAdvantages v-if="mq.mdPlus" :advantages="content.elements" v-reveal="{delay: 250}" />
    <MobileSwiperAdvantages v-else :advantages="content.elements" />
</template>

<script>
import {MobileSwiperAdvantages, SwiperAdvantages} from '@/components/swipers'

export default {
    name: 'AdvantagesBlock',
    inject: ['mq'],
    components: {SwiperAdvantages, MobileSwiperAdvantages},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>