<template>
    <section class="events -testimonies" @click="slideRight">
      <swiper :slides-per-view="1" :space-between="30" :loop="true" @swiper="onSwiper">
        <swiper-slide v-for="(slide, index) in testimonies" :key="index" class="business__card -testimonies">
          <h2 class="business__card-title -testimonies">«&nbsp;{{ slide.title }}&nbsp;»</h2>
          <p class="business__card-description -testimonies">{{ slide.description }}</p>
          <p class="business__card-name">{{ slide.name }}</p>   
        </swiper-slide>
      </swiper>
    </section>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from "swiper/vue";

  export default {
    name: "SwiperTestimonies",
    components: { Swiper, SwiperSlide },
    data() {
      return {
        swiper: null
      };
    },
    props: {
      testimonies: {
        type: Array,
        required: true
      },
    },
    methods: {
      onSwiper(swiper) {
        this.swiper = swiper;
      },
      slideRight() {
        this.swiper.slideNext();
      },
    },
  };
  </script>
  