<template>
    <div class="profiles__card">
        <Image class="image__profile" :source="content.image" :alt="content.name" />
        <div class="profiles__description">
            <p class="profiles__name">{{ content.name }}</p>
            <p class="profiles__experience">{{ content.experience }}</p>
            <p class="profiles__text">{{ content.description }}</p>
            <RawHtml class="profiles__list list" :html="content.history"/>
        </div>
    </div>
</template>

<script>
import {Image, RawHtml} from '@/components/global'

export default {
    name: 'ProfileCard',
    components: {
        Image,
        RawHtml
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>