<template>
    <section class="job__description section" data-light-menu="false">
        <div class="job__description-left">
            <h2 class="job__description-title" v-reveal>{{ content.description }}</h2>
            <RawHtml class="job__description-content markdown" :html="job.description" v-reveal="{delay: 200}"/>

            <h2 class="job__description-title" v-reveal="{delay: 250}">{{ content.conditions }}</h2>
            <RawHtml class="job__description-content markdown" :html="job.conditions" v-reveal="{delay: 300}"/>

            <h2 class="job__description-title" v-reveal>{{ content.required }}</h2>
            <RawHtml class="job__description-content markdown" :html="job.required" v-reveal="{delay: 200}"/>

            <div v-reveal>
                <LangRouterLink :to="{name: $route.name, hash: `#${$t('anchors.contact')}`}" class="button -dark">
                    {{ content.button }}
                </LangRouterLink>
            </div>
        </div>
        <div class="job__description-right">
            <JobValues/>
            <div>
                <p class="job__about-title" v-reveal="{delay: 250}">{{ content.aboutUs.title }}</p>
                <p class="job__about-description" v-reveal="{delay: 300}">{{ content.aboutUs.description }}</p>
                <div v-reveal="{delay: 350}">
                    <LangRouterLink :to="{name: 'home'}" class="button -dark job__about-button">
                        <Icon id="arrow"/>
                    </LangRouterLink>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {JobValues} from '@/components/blocks'
import {Icon, LangRouterLink, RawHtml} from '@/components/global'

export default {
    name: 'JobContent',
    components: {
        Icon,
        JobValues,
        LangRouterLink,
        RawHtml
    },
    props: {
        content: {
            type: Object,
            required: true
        },
        job: {
            type: Object,
            required: true
        }
    }
}
</script>
