<template>
    <section class="advantages__background section advantages" :id="$t('anchors.experiences')" data-light-menu="false" :data-section="$t('anchors.experiences')">
        <ValuesBlock :content="content.values"/>
        <AdvantagesBlock :content="content.advantages"/>
    </section>
</template>

<script>
import {AdvantagesBlock, ValuesBlock} from '@/components/blocks'
export default {
    name: 'PresentationSection',
    components: {
        AdvantagesBlock,
        ValuesBlock
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>