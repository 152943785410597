<template>
    <div class="advantages__value">
        <Image :source="content.image" class="advantages__value-image" />
        <p class="advantages__value-title">{{ content.name }}</p>
        <p class="advantages__value-text">{{ content.description }}</p>
    </div>
</template>

<script>
import {Image} from '@/components/global'

export default {
    name: 'ValueCard',
    components: {
        Image
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>
