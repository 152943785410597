<template>
    <transition name="fade">
        <Loader v-if="!$beet.isReady"/>
    </transition>

    <template v-if="$beet.isReady">
        <SiteNav/>

        <router-view v-slot="{ Component }">
            <transition name="fade-fast" mode="out-in">
                <component :is="Component" :key="$route.path"/>
            </transition>
        </router-view>
        <Gdpr :options="{backgroundColor: '#6283ba', color: '#fff'}"/>
        <Footer/>
    </template>
</template>

<script>
import SiteNav from '@/components/SiteNav.vue'
import {Footer} from '@/components/sections'
import {Loader} from '@/components/small'
import {useLangRouter} from '@/extensions/langRouter'
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
    name: 'App',
    setup() {
        useLangRouter()
    },
    components: {Gdpr, Footer, Loader, SiteNav},
    watch: {
        '$i18n.locale': {
            handler: function (to) {
                this.$beet.fetchCoreData(to)
            },
            immediate: true
        },
        $route: {
            handler(to, from = {}) {
                const delay = to.name !== from.name ? 400 : 0

                setTimeout(() => {
                    const el = to.hash ? document.querySelector(to.hash) : null
                    if (el) {
                        el.scrollIntoView({behavior: 'smooth', block: 'start'})
                    } else {
                        window.scrollTo({top: 0, behavior: 'smooth'})
                    }
                }, delay)
            },
            immediate: true
        }
    }
}
</script>
