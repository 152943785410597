<template>
  <section class="business">
    <swiper slides-per-view="auto" :space-between="16" :loop="true" @swiper="onSwiper">
        <swiper-slide
          v-for="(business, businessIndex) in slides"
          :key="businessIndex"
          class="business__card -section"
          :class="{'-switch': switchPositions}"
          target="_blank"
        >
          <button class="business__image" @click="slideRight">
            <img class="image__business" :src="business.image.original" :alt="business.name" />
          </button>
          <a
            :href="business.link"
            target="_blank"
            class="business__card-content -section"
          >
            <img :src="business.blackLogo.original" alt="" class="business__card-logo" />
            <p class="business__card-description">{{ business.description }}</p>
          </a>
        </swiper-slide>
      </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "SwiperSection",
  components: { Swiper, SwiperSlide },
  inject: ['mq'],
  data() {
    return {
      swiper: null,
      switchPositions: false,
      click: 0
    };
  },
  props: {
    businesses: {
      type: Object,
      required: true
    },
  },
  computed: {
    slides() {
      return Object.values(this.businesses).concat(
        Object.values(this.businesses)
      );
    },
  },
  methods: {
    onSwiper(swiper) {
        this.swiper = swiper;
      },
    slideRight() {
      this.swiper.slideNext();
      this.click ++;
      if (this.click >= 4 && !this.mq.xxl) {
        this.switchPositions = this.click % 2 === 0;
      }
    },
  },
};
</script>
