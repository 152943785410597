<template>
    <section class="header__background section" id="header" data-light-menu="true" data-section="header">
        <div class="header">
            <Image :source="content.imageLeft" class="image__rounded header__img-left" v-reveal/>
            <Image :source="content.imageRight" class="image__rounded header__img-right" v-reveal="{delay: 250}"/>
            <h1 v-reveal="{delay: 200}">{{ content.title }}</h1>
            <div class="header__content">
                <p class="description -subtitle" v-reveal="{delay: 300}">{{ content.description }}</p>
                <div v-reveal="{delay: 350}">
                    <LangRouterLink :to="{name: 'home', hash: `#${$t('anchors.jobs')}` }" class="button -blue header__button">
                        {{ content.buttonLabel }}
                    </LangRouterLink>
                </div>
            </div>
            <h2 class="header__places-title description" v-reveal>
                {{ content.otherPlaces }}
            </h2>
            <div class="header__places" v-reveal="{delay: 250}">
                <BusinessLink v-for="(business, businessIndex) in $beet.businesses" :key="businessIndex"
                              :content="business"/>
            </div>
        </div>
    </section>
</template>

<script>
import {Image, LangRouterLink} from '@/components/global'
import {BusinessLink} from '@/components/small'

export default {
    name: 'HomeHeader',
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        BusinessLink,
        Image,
        LangRouterLink
    }
}
</script>
