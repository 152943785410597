import {createStore} from "vuex";
import http from "@/extensions/http";

const createSlugMap =
    typeKey =>
        ({ beet }) => {
            const data = beet.data[typeKey] || {}
            return Object.values(data).reduce((slugMap, { id, slug }) => ({ ...slugMap, [slug]: id }), {})
        }

export default createStore({
    state: {
        menu: ['business', 'experiences', 'profiles', 'jobs', 'contact']
    },
    getters: {
        businessesSlugMap: createSlugMap('businesses'),
        jobsSlugMap: createSlugMap('jobs'),
        menu: state => state.menu,
    },
    mutations: {},
    actions: {
        sendForm(_, inputs) {
            return new Promise((resolve, reject) => {
                http
                    .post("/job-application/create", inputs)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    modules: {},
});
