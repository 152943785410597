<template>
    <section class="job__header section" data-light-menu="true">
        <div class="job__header-container">
            <LangRouterLink :to="{ name: 'home' }" class="job__header-back" v-reveal>
                <Icon id="arrow" class="job__header-icon"/>
                <span>{{ content.back }}</span>
            </LangRouterLink>
            <h1 class="job__title" v-reveal="{delay: 200}">
                {{ `${job.title} | ${$beet.jobTypes[job.jobType].name}` }}
            </h1>
            <div v-reveal="{delay: 250}">
                <a :href="business.link" target="_blank">
                    <Image :source="business.detailedLogo" class="job__header-logo"/>
                </a>
            </div>
            <ul class="job__header-list list">
                <li v-if="job.workHours" v-reveal="{delay: 300}">
                    {{ $t("hours", {hours: job.workHours}) }}
                </li>
                <li v-if="job.hourlyRate" v-reveal="{delay: 350}">
                    {{ $t("salary", {salary: job.hourlyRate}) }}
                </li>
                <li v-if="job.experience" v-reveal="{delay: 400}">{{ job.experience }}</li>
            </ul>
            <div class="job__header-actions">
                <div v-reveal="{delay: 350}">
                    <LangRouterLink :to="{name: $route.name, hash: `#${$t('anchors.contact')}`}" class="button -blue">
                        {{ content.button }}
                    </LangRouterLink>
                </div>
                <button class="job__header-share" v-if="isMobile" @click="share">
                    <Icon id="share" class="job__header-share-icon -mobile"/>
                </button>
                <button v-if="!isMobile" class="job__header-share" v-reveal="{delay: 400}">
                    <Icon id="share" class="job__header-share-icon"/>
                    <div class="job__header-options">
                        <Icon id="facebook" @click.prevent.stop="desktopShare('fb')"/>
                        <Icon id="linkedin" @click.prevent.stop="desktopShare('in')"/>
                        <Icon id="mail" @click.prevent.stop="desktopShare('mail')"/>
                    </div>
                    <span>{{ content.share }}</span>
                </button>
            </div>
            <Image :source="job.image" class="job__header-image image__page-job" v-reveal="{delay: 400}"/>
        </div>
    </section>
</template>

<script>
import {Icon, Image, LangRouterLink} from '@/components/global'
import UAParser from 'ua-parser-js'

export default {
    name: 'JobHeader',
    props: {
        business: {
          type: Object,
          required: true
        },
        content: {
            type: Object,
            required: true
        },
        job: {
            type: Object,
            required: true
        }
    },
    components: {
        Icon,
        Image,
        LangRouterLink
    },
    computed: {
        isMobile() {
            const userAgent = navigator.userAgent;
            const parser = new UAParser(userAgent);
            const device = parser.getDevice();
            return device.type === 'mobile' || device.type === 'tablet';
        }
    },
    methods: {
        share() {
            navigator.share({
                title: this.job.title,
                url: window.location.href
            });
        },
        desktopShare(provider) {
            const platforms = {
                fb: [
                    `https://facebook.com/sharer/sharer.php?u=${window.location.href}`,
                    '_blank',
                    'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
                ],
                in: [
                    `https://www.linkedin.com/sharing/share-offsite/?summary=Groupe Maestro - ${this.job.title}&url=${window.location.href}`,
                    '_blank',
                    'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
                ],
                mail: [
                    `mailto:?subject=Offre d'emploi - ${this.job.title}&body=${window.location.href}`,
                    '_blank'
                ],
            }
            window.open(...platforms[provider])
        }
    }
}
</script>
