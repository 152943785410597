<template>
    <a class="header__place" :href="content.link" target="_blank">
        <Image :source="content.logo" :alt="content.name"/>
    </a>
</template>

<script>
import Image from '@/components/global/Image.vue'

export default {
    name: 'BusinessLink',
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        Image
    }
}
</script>

