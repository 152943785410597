<template>
    <section class="business__mobile -testimonies">
        <swiper :modules="modules" :pagination="true" slides-per-view="auto" :space-between="10" :loop="true">
          <swiper-slide
            v-for="(slide, index) in testimonies"
            :key="index"
            class="business__element-mobile -history"
            target="_blank"
          >
            <h2 class="business__card-title -testimonies">«&nbsp;{{ slide.title }}&nbsp;»</h2>
            <p class="business__card-description -testimonies">{{ slide.description }}</p>
            <p class="business__card-name">{{ slide.name }}</p>   
          </swiper-slide>
        </swiper>
    </section>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Pagination } from 'swiper';
  
  export default {
    name: "MobileSwiperTestimonies",
    components: { Swiper, SwiperSlide },
    data() {
      return {
          modules: [Pagination]
      }
    },
    props: {
      testimonies: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  