<template>
    <section id="testimonies" data-light-menu="true" :data-section="$t('anchors.experiences')">
        <div class="testimonies testimonies__background section">
            <SwiperTestimonies v-if="mq.mdPlus" :testimonies="content.elements" v-reveal/>
            <MobileSwiperTestimonies v-else :testimonies="content.elements"/>
            <RoundTextButton place="testimonies" :text="content.roundButton" :to="{name: 'home', hash: `#${$t('anchors.jobs')}`}"/>
        </div>

        <Image class="image__background" :source="content.image" v-reveal="{delay: 250}" />
    </section>
</template>

<script>
import {Image} from '@/components/global'
import {RoundTextButton} from '@/components/small'
import {MobileSwiperTestimonies, SwiperTestimonies} from '@/components/swipers'

export default {
    name: 'TestimoniesSection',
    components: {Image, MobileSwiperTestimonies, RoundTextButton, SwiperTestimonies},
    inject: ['mq'],
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>
