<template>
    <h2 class="advantages__values-title" v-reveal>{{ content.title }}</h2>
    <p class="description -subtitle" v-reveal="{delay: 200}">{{ content.subtitle }}</p>
    <div class="advantages__values">
        <ValueCard v-for="(value, valueIndex) in $beet.values" :content="value" :key="valueIndex" v-reveal="{delay: 250 + (50 * valueIndex)}"/>
    </div>
</template>

<script>
import {ValueCard} from '@/components/cards'

export default {
    name: 'ValuesBlock',
    components: {ValueCard},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>