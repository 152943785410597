export default {
    anchors: {
        business: 'businesses',
        contact: 'contact',
        experiences: 'experiences',
        jobs: 'jobs',
        profiles: 'profiles'
    },
    beet: 'Site designed and developed by',
    cvLimit: "The file must not be larger than 10 Mb.",
    hours: "{hours} hours per week",
    salary: "{salary}$ an hour",
    errors: {
        file: 'The file must not be larger than 10 Mb.',
        email: "The email address is invalid.",
        emailSize: "The email's length must not exceed 255 characters.",
        title: "The title's length must not exceed 255 characters.",
        motivation: "The motivation text length must not exceed 500 characters.",
        name: "The name's length must not exceed 255 characters."
    }
}
