<template>
  <section class="business__mobile -section">
      <swiper :modules="modules" :free-mode="true" slides-per-view="auto" :space-between="16">
        <swiper-slide
          v-for="(business, businessIndex) in businesses"
          :key="businessIndex"
          class="business__element-mobile"
          target="_blank"
        >
          <button class="business__image">
            <img class="image__business" :src="business.image.original" :alt="business.name" />
          </button>
          <a
            :href="business.link"
            target="_blank"
            class="business__card-content"
          >
            <img :src="business.blackLogo.original" alt="" class="business__card-logo" />
            <p class="business__card-description">{{ business.description }}</p>
          </a>
        </swiper-slide>
      </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode } from 'swiper';

export default {
  name: "SwiperSection",
  components: { Swiper, SwiperSlide },
  data() {
    return {
        modules: [FreeMode]
    }
  },
  props: {
    businesses: {
      type: Object,
      required: true,
    },
  },
};
</script>
