<template>
    <LangRouterLink :to="{ name: 'job', params: { jobSlug: content.slug } }" class="jobs__job">
        <img class="image__job" :src="content.image.original" :alt="content.title">
        <p class="jobs__job-title">{{ content.title }} | {{ $beet.jobTypes[content.jobType].name }}</p>
        <p class="jobs__job-company">{{ $beet.businesses[content.business].name }}</p>
    </LangRouterLink>
</template>

<script>
import {LangRouterLink} from '@/components/global'
export default {
    name: 'JobCard',
    components: {
        LangRouterLink
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>