<template>
    <div class="experiences">
        <div class="experiences__left">
            <h2 class="experiences__title" v-reveal>{{ content.title }}</h2>
            <p class="experiences__description description" v-reveal="{delay: 200}">
                {{ content.subtitle }}
            </p>
        </div>
        <SwiperSection v-if="mq.mdPlus" :businesses="$beet.businesses" v-reveal="{delay: 250}"/>
        <MobileSwiperSection v-else :businesses="$beet.businesses"/>
    </div>
</template>

<script>
import {MobileSwiperSection, SwiperSection} from '@/components/swipers'

export default {
    name: 'BusinessesBlock',
    inject: ['mq'],
    components: {MobileSwiperSection, SwiperSection},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>

