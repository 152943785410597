<template>
    <footer class="footer" v-if="$beet.isReady">
        <p v-reveal>
            <span>{{ credits }}</span>
            <!-- <LangRouterLink class="footer__link" :to="{ name: 'privacy' }"> {{ $beet.options.navigation.privacy }}</LangRouterLink> -->
        </p>
        <p v-if="$beet.options.footer?.privacy?.link" v-reveal="{delay: 100}">
          <a :href="$beet.options.footer?.privacy?.link" target="_blank" rel="nofollow">
             {{ $beet.options.footer?.privacy?.label }}
          </a>
        </p>
        <p v-reveal="{delay: 200}">
            <span>{{ `${$t('beet')} ` }}</span>
            <a href="https://boitebeet.com" target="_blank">Beet</a>
        </p>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    computed: {
        credits() {
            return this.$beet.options.footer.credits.replace('[YEAR]', `${new Date().getFullYear()} `);
        }
    }
};
</script>
