<template>
    <section class="business -advantages" @click="slideRight">
        <Swiper slides-per-view="auto" :space-between="16" :loop="true" @swiper="onSwiper">
            <SwiperSlide v-for="(advantage, index) in slides" :key="index"
                         :class="['business__card', '-section', '-advantages', {'-switch': switchPosition}]">
                <Image class="image__business -advantages" :source="advantage.image"/>
                <div class="business__card-content -advantages">
                    <p class="business__card-title -advantages">{{ advantage.title }}</p>
                    <p class="business__card-description -advantages">
                        {{ advantage.description }}
                    </p>
                </div>
            </SwiperSlide>
        </Swiper>
    </section>
</template>

<script>
import {Image} from '@/components/global'
import {Swiper, SwiperSlide} from 'swiper/vue'

export default {
    name: 'SwiperAdvantages',
    components: {Image, Swiper, SwiperSlide},
    data() {
        return {
            swiper: null,
            switchPosition: false
        };
    },
    props: {
        advantages: {
            type: Array,
            required: true
        }
    },
    computed: {
        slides() {
            return this.advantages.concat(this.advantages, this.advantages, this.advantages, this.advantages, this.advantages)
        }
    },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper
        },
        slideRight() {
            this.swiper.slideNext()
        }
    }
}
</script>
