<template>
    <section class="jobs__background section" :id="$t('anchors.jobs')" data-light-menu="false"
             :data-section="$t('anchors.jobs')">
        <div class="jobs">
            <h2 class="jobs__title" v-reveal>{{ content.title }}</h2>
            <p class="description -subtitle" v-reveal="{delay: 200}">{{ content.subtitle }}</p>
            <div class="jobs__filters">
                <div class="input -select -dark" v-reveal="{delay: 250}">
                    <select class="input__select -filter" name="companies" id="companies" v-model="company">
                        <option :value="null">{{ content.defaultPlaces }}</option>
                        <option v-for="(option, id) in $beet.businesses" :key="`company-${id}`" :value="option.slug">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="input -select -dark" v-reveal="{delay: 300}">
                    <select class="input__select -filter" name="types" id="types" v-model="jobType">
                        <option :value="null">{{ content.defaultShift }}</option>
                        <option v-for="(option, id) in $beet.jobTypes" :key="`type-${id}`" :value="id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="jobs__container" v-reveal="{delay: 200}">
                <JobCard :content="job" v-for="(job, jobIndex) in currentShownJobs" :key="jobIndex"/>
                <button v-if="currentShownJobs.length < currentJobs.length" class="button -dark jobs__button"
                        @click="page++">{{ content.button }}
                </button>
                <p v-if="currentJobs.length === 0" class="jobs__empty">{{ content.empty }}</p>
            </div>
        </div>
    </section>
</template>

<script>
import {JobCard} from '@/components/cards'
import {HasRecentJobs} from '@/mixins'

export default {
    name: 'JobsSection',
    mixins: [HasRecentJobs],
    components: {JobCard},
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            company: null,
            jobType: null,
            page: 1,
        }
    },
    computed: {
        currentShownJobs() {
            return this.currentJobs.slice(0, (this.page * 8));
        },
        currentJobs() {
            let jobs = this.recentJobs

            if (this.$route.query.business) {
                jobs = jobs.filter(job => job.business === this.$store.getters.businessesSlugMap[this.$route.query.business])
            }

            if (this.jobType) {
                jobs = jobs.filter(job => job.jobType.toString() === this.jobType)
            }

            return jobs
        }
    },
    watch: {
        company(to) {
            this.$router.push({
                name: 'home',
                hash: `#${this.$t('anchors.jobs')}`,
                query: to ? {business: to} : {}
            })
        }
    }
}
</script>
