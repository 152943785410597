<template>
    <section class="" :id="$t('anchors.profiles')" data-light-menu="true" :data-section="$t('anchors.profiles')">
        <div class="profiles section">
            <h2 class="profiles__title" v-reveal>{{ content.title }}</h2>
            <p class="description -subtitle profiles__subtitle" v-reveal="{delay: 200}">{{ content.subtitle }}</p>
            <div class="profiles__cards" v-if="mq.mdPlus">
                <ProfileCard v-for="(profile, profileIndex) in content.elements" :key="profileIndex" :content="profile" v-reveal="{delay: 250 + (50 * profileIndex)}"/>
            </div>
            <MobileSwiperProfiles :profiles="$page.profiles.elements" v-else/>
            <RoundTextButton place="profiles" :text="content.roundButton" :to="{name: 'home', hash: `#${$t('anchors.jobs')}`}"/>
        </div>

        <Image class="image__background" :source="content.image" v-reveal="{delay: 200}" />
    </section>
</template>

<script>
import {ProfileCard} from '@/components/cards'
import {Image} from '@/components/global'
import {RoundTextButton} from '@/components/small'
import {MobileSwiperProfiles} from '@/components/swipers'

export default {
    name: 'ProfilesSection',
    inject: ['mq'],
    components: {Image, MobileSwiperProfiles, ProfileCard, RoundTextButton},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>
