<template>
    <form class="form" @submit.prevent="sendForm">
        <div class="form__errors" :class="{ '-dark': jobId }" v-if="Object.keys(errors).length > 0">
            <div class="form__errors-icon">
                <Icon id="alert"/>
            </div>
            <p class="form__error" v-for="(error, key) in errors" :key="key">
                {{ error[0] }}
            </p>
        </div>
        <div v-if="formSent" class="form__success" :class="{ '-dark': jobId }">
            <div class="form__success-icon">
                <Icon id="checkmark"/>
            </div>
            <p>{{ $beet.options.form.success }}</p>
        </div>
        <div class="form__row">
            <div v-reveal>
                <label for="name" class="form__label">{{ $beet.options.form.labels.name }}</label>
                <input type="text" id="name" name="name" v-model="inputs.name" class="input"/>
            </div>
            <div v-reveal="{delay: 200}">
                <label for="email" class="form__label">{{ $beet.options.form.labels.email }}</label>
                <input type="email" id="email" name="email" v-model="inputs.email" class="input"/>
            </div>
        </div>
        <div class="form__row" v-if="!jobId">
            <div v-if="!jobId" v-reveal="{delay: 250}">
                <label for="job_title" class="form__label">{{ $beet.options.form.labels.title }}</label>
                <input type="text" id="job_title" name="job_title" class="input" v-model="inputs.job_title"/>
            </div>
            <div v-if="!jobId" v-reveal="{delay: 300}">
                <label for="shift" class="form__label">{{ $beet.options.form.labels.shift }}</label>
                <div class="input -select">
                    <select id="shift" name="shift" v-model="inputs.job_type" class="input__select">
                        <option v-for="(option, id) in $beet.jobTypes" :key="`shift-${id}`" :value="id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form__row">
            <div v-reveal="{delay: jobId ? 350 : 250}">
                <label for="motivation" class="form__label">{{ $beet.options.form.labels.description }}</label>
                <textarea id="motivation" name="motivation" v-model="inputs.motivation" @input="checkLength" class="input -textarea"/>
            </div>
        </div>
        <div class="form__row -submit">
            <div v-reveal="{delay: 200}">
                <input type="file" id="file" @change="saveFile" class="input__hidden"/>
                <label class="form__file" for="file">
                    <span>{{ inputs.resume ? inputs.resume.name : $beet.options.form.labels.cv }}</span>
                    <button type="button" class="form__remove" @click="inputs.resume = null" v-if="inputs.resume">
                        <Icon id="close"/>
                    </button>
                    <Icon v-else id="download"/>
                </label>
                <p>{{ $t("cvLimit") }}</p>
            </div>
            <div v-reveal="{delay: 250}">
                <button :disabled="isDisabled" :class="['button', { '-green': !this.jobId }]">
                    {{ $beet.options.form.button }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import {Icon} from '@/components/global'

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
    name: 'ApplyForm',
    props: {
        jobId: {
            type: Number,
            default: null,
        }
    },
    components: {
        Icon
    },
    data() {
        return {
            inputs: {
                name: null,
                email: null,
                job_title: null,
                job_type: null,
                motivation: null,
                resume: null,
                job: null,
            },
            errors: {},
            formSent: false,
        }
    },
    mounted() {
        if (this.jobId) {
            this.inputs.job_title = this.currentJob.title
            this.inputs.job_type = this.currentJob.jobType
            this.inputs.job = this.jobId
        }
    },
    computed: {
        currentJobType() {
            return this.jobId ? this.$beet.jobTypes[this.currentJob.jobTypeId].name : null
        },
        currentJob() {
            return this.jobId ? this.$beet.jobs[this.jobId] : null
        },
        isDisabled() {
            return (
                !this.inputs.name ||
                !this.inputs.email ||
                !this.inputs.job_title ||
                !this.inputs.job_type ||
                !this.inputs.motivation ||
                !this.inputs.resume
            )
        },
    },
    methods: {
        sendForm() {
            this.errors = {}

            if (this.inputs.resume.size / 1024 / 1024 > 10) {
                this.errors["resume"] = [this.$t('errors.file')]
            }
            if (this.inputs.email.length > 255) {
                this.errors['email'] = [this.$t('errors.emailSize')]
            }
            if (!EMAIL_REGEX.test(this.inputs.email)) {
                this.errors['email'] = [this.$t('errors.email')]
            }
            if (this.inputs.job_title.length > 255) {
                this.errors['titre'] = [this.$t('errors.title')]
            }
            if (this.inputs.name.length > 255) {
                this.errors['name'] = [this.$t('errors.name')]
            }
            if (this.inputs.motivation.length > 500) {
                this.errors['motivation'] = [this.$t('errors.motivation')]
            }

            if (Object.keys(this.errors).length === 0) {
                let formData = new FormData()
                formData.append("resume", this.inputs.resume)
                formData.append("name", this.inputs.name)
                formData.append("email", this.inputs.email)
                formData.append("job_type", this.inputs.job_type)
                formData.append("job_title", this.inputs.job_title)
                formData.append("motivation", this.inputs.motivation)
                if (this.inputs.job) {
                    formData.append("job", this.inputs.job)
                }
                this.$store
                    .dispatch("sendForm", formData)
                    .then(() => {
                        this.formSent = true
                        this.inputs = {
                            name: null,
                            email: null,
                            job_title: null,
                            job_type: null,
                            motivation: null,
                            resume: null,
                            job: null,
                        }
                    })
                    .catch((err) => {
                        if (err.response.data) {
                            this.errors = err.response.data.errors
                        }
                    })
            }
        },
        checkLength() {
            this.inputs.motivation = this.inputs.motivation.substr(0, 500)
        },
        saveFile(event) {
            this.inputs.resume = event.target.files[0]
        },
    },
}
</script>
