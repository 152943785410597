<template>
  <section class="business__mobile">
      <swiper :modules="modules" :pagination="true" :space-between="10" :loop="true">
        <swiper-slide
          v-for="(slide, index) in events"
          :key="index"
          class="business__element-mobile -history"
          target="_blank"
        >
          <p class="events__year">{{  slide.year  }}</p>
          <p class="business__card-description events__description">{{ slide.description }}</p>
        </swiper-slide>
      </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from 'swiper';

export default {
  name: "MobileSwiperHistory",
  components: { Swiper, SwiperSlide },
  data() {
    return {
        modules: [Pagination]
    }
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
};
</script>
