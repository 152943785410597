<template>
    <section class="banner__background section" id="banner" data-light-menu="true" :data-section="$t('anchors.business')">
        <div class="banner">
            <Image :source="content.imageLeft" class="image__banner banner__img-left" v-reveal />
            <Image :source="content.imageRight" class="image__banner banner__img-right" v-reveal="{delay: 250}" />
            <h2 class="banner__title" v-reveal="{delay: 200}">{{ content.title }}</h2>
            <p class="description -subtitle banner__subtitle" v-reveal="{delay: 300}">{{ content.subtitle }}</p>
            <div v-reveal="{delay: 350}">
                <LangRouterLink :to="{name: 'home', hash: `#${$t('anchors.jobs')}`}" class="button banner__button">
                    {{ content.buttonLabel }}
                </LangRouterLink>
            </div>
            <RoundTextButton :text="content.roundButton" :to="{name: 'home', hash: `#${$t('anchors.jobs')}`}"/>
        </div>
    </section>
</template>

<script>
import {Image} from '@/components/global'
import {RoundTextButton} from '@/components/small'
import LangRouterLink from "@/components/global/LangRouterLink.vue";

export default {
    name: 'BannerSection',
    components: {LangRouterLink, Image, RoundTextButton},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>