<template>
    <LangRouterLink :to="to">
        <div class="rounded-text" :class="`-${place}`">
            <svg viewBox="0 0 100 100" width="250" height="250">
                <defs>
                    <path id="circle" d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0"/>
                </defs>
                <text>
                    <textPath xlink:href="#circle" class="rounded-text__text">
                        {{ text }}
                    </textPath>
                </text>
            </svg>
        </div>
        <Icon class="rounded-text__arrow" :class="`-${place}`" id="arrow"/>
    </LangRouterLink>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/global'

export default {
    name: 'RoundTextButton',
    props: {
        place: {
            type: String,
            required: false,
            default: 'banner'
        },
        text: {
            type: String,
            required: true,
        },
        to: {
            type: Object,
            required: true
        }
    },
    components: {Icon, LangRouterLink}
};
</script>
