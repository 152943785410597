<template>
  <section @click="slideRight">
    <swiper slides-per-view="3" :space-between="20" :loop="true" class="business__history" @swiper="onSwiper">
        <swiper-slide
          v-for="(slide, index) in events"
          :key="index"
          class="business__card -history"
          target="_blank"
        >
          <p class="events__year">{{  slide.year  }}</p>
          <p class="business__card-description events__description">{{ slide.description }}</p>
        </swiper-slide>
      </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "SwiperSection",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      swiper: null
    };
  },
  props: {
    events: {
      type: Array,
      required: true
    },
  },
  methods: {
    onSwiper(swiper) {
        this.swiper = swiper;
      },
    slideRight() {
      this.swiper.slideNext();
    },
  }
};
</script>
