<template>
    <header :class="['navbar', { '-dark': !lightMode, '-opened': menuOpened }]" class="navbar" ref="nav">
        <nav class="navbar__top">
            <LangRouterLink class="navbar__logo" :to="{name: 'home'}" @click="scrollTopSafety">
                <Icon id="decoration" class="navbar__icon"/>
                <span class="navbar__name">{{ $beet.options.navigation.site }}</span>
            </LangRouterLink>
            <div class="navbar__links" v-if="mq.mdPlus">
                <LangRouterLink :to="{ name: 'home', hash: `#${$t(`anchors.${item}`)}` }"
                                :class="['navbar__link', {'-current': currentSection === $t(`anchors.${item}`)}]"
                                v-for="(item, itemKey) in $store.getters.menu" :key="itemKey">
                    {{ $beet.options.navigation[item] }}
                </LangRouterLink>
            </div>
            <button class="navbar__mobile-button" @click="menuOpened = !menuOpened" v-else>
                <Icon :id="menuOpened ? 'close' : 'menu'"/>
            </button>
        </nav>
        <DropDown :is-open="menuOpened" v-if="mq.mdMinus">
            <nav class="navbar__mobile-menu">
                <LangRouterLink class="navbar__mobile-link" v-for="(item, itemKey) in $store.getters.menu"
                                :to="{name: 'home', hash: `#${$t(`anchors.${item}`)}`}" :key="itemKey">
                    {{ $beet.options.navigation[item] }}
                </LangRouterLink>
            </nav>
        </DropDown>
    </header>
</template>

<script>
import {DropDown, Icon, LangRouterLink} from '@/components/global'

export default {
    name: 'SiteNav',
    components: {DropDown, Icon, LangRouterLink},
    inject: ['mq'],
    data() {
        return {
            currentSection: null,
            lightMode: true,
            observer: null,
            menuOpened: false,
            prevYPosition: 0,
            scrollDown: true,
            sections: null
        }
    },
    methods: {
        getTargetSection(target) {
            if (!this.scrollDown) return target

            return target.nextElementSibling ?? target
        },
        observerCallback(entries) {
            entries.forEach(entry => {
                this.setScrollDirection()

                if (!this.shouldUpdate(entry)) return

                const target = this.getTargetSection(entry.target)
                this.updateLightMode(target)
                this.updateCurrentSection(target)
            })
        },
        resetData() {
            this.currentSection = null
            this.observer = null
            this.prevYPosition = 0
            this.scrollDown = true
            this.sections = null
        },
        scrollTopSafety() {
            this.$nextTick(() => {
                if (this.$route.name === 'home') window.scrollTo({top: 0, behavior: 'smooth'})
            })
        },
        setScrollObserver() {
            this.sections = [...document.querySelectorAll('section[data-light-menu]')]
            if (!this.sections.length) window.setTimeout(this.setScrollObserver, 300)
            else {
                this.observer = new IntersectionObserver(this.observerCallback, {
                    rootMargin: `-${this.$refs.nav.offsetHeight / 2}px`,
                    threshold: 0
                })
                this.sections.forEach(section => {
                    this.observer.observe(section)
                })
            }
        },
        setScrollDirection() {
            this.scrollDown = window.scrollY > this.prevYPosition
            this.prevYPosition = window.scrollY
        },
        shouldUpdate(entry) {
            return (this.scrollDown && !entry.isIntersecting) || (!this.scrollDown && entry.isIntersecting)
        },
        updateCurrentSection(target) {
            this.currentSection = target.dataset.section
        },
        updateLightMode(target) {
            if (window.scrollY > 0) {
                this.lightMode = target.dataset.lightMenu === 'true'
            }
        }
    },
    watch: {
        '$route.name': {
            handler() {
                this.resetData()
                window.setTimeout(this.setScrollObserver, 400)
            },
            immediate: true
        },
        '$route'() {
            this.menuOpened = false
        }
    }
}
</script>
