<template>
    <div class="job__values">
        <div class="job__value" v-for="(value, index) in $beet.values" :key="index" v-reveal="{delay: 200 + (75 * index)}">
            <Image :source="value.image"  class="job__value-image"/>
            <p class="job__value-name">{{ value.name }}</p>
        </div>
    </div>
</template>

<script>
import {Image} from '@/components/global'

export default {
    name: 'JobValues',
    components: {
        Image
    }
}
</script>