<template>
    <section class="business__mobile -section">
        <swiper :modules="modules" :free-mode="true" slides-per-view="auto" :space-between="16">
          <swiper-slide
            v-for="(advantage, index) in advantages"
            :key="index"
            class="business__element-mobile"
            target="_blank"
          >
          <img
            class="image__business -advantages -mobile"
            :src="advantage.image.original"
            alt=""
          />
          <div class="business__card-content -advantages">
            <p class="business__card-title -advantages">{{ advantage.title }}</p>
            <p class="business__card-description -advantages">
              {{ advantage.description }}
            </p>
          </div>
          </swiper-slide>
        </swiper>
    </section>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { FreeMode } from 'swiper';
  
  export default {
    name: "MobileSwiperAdvantages",
    components: { Swiper, SwiperSlide },
    data() {
      return {
          modules: [FreeMode]
      }
    },
    props: {
      advantages: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  