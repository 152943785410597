<template>
  <div class="loader__container" id="loader">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>
