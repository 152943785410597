<template>
    <div class="history">
        <h2 class="history__title" v-reveal>{{ content.title }}</h2>
        <p class="description -subtitle history__description" v-reveal="{delay: 200}">
            {{ content.subtitle }}
        </p>
        <SwiperHistory v-if="mq.mdPlus" :events="content.events" v-reveal="{delay: 250}"/>
        <MobileSwiperHistory v-else :events="content.events"/>
    </div>
</template>

<script>
import {MobileSwiperHistory, SwiperHistory} from '@/components/swipers'

export default {
    name: 'HistoryBlock',
    inject: ['mq'],
    components: {SwiperHistory, MobileSwiperHistory},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>