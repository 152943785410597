<template>
    <section class="experiences__background section" :id="$t('anchors.business')" data-light-menu="false" :data-section="$t('anchors.business')">
        <BusinessesBlock :content="content.experiences"/>
        <HistoryBlock :content="content.history"/>
    </section>
</template>

<script>
import {BusinessesBlock, HistoryBlock} from '@/components/blocks'

export default {
    name: 'IntroductionSection',
    components: {BusinessesBlock, HistoryBlock},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>
